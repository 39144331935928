<template>
  <!--begin::Content-->
  <div class="flex-lg-row-fluid ms-lg-7 ms-xl-10">
    <!--begin::Messenger-->
    <div class="card" id="kt_chat_messenger">
      <!--begin::Card header-->
      <div class="card-header" id="kt_chat_messenger_header">
        <!--begin::Title-->
        <div class="card-title">
          <!--begin::User-->
          <div class="d-flex justify-content-center flex-column me-3">
            <a
              href="#"
              class="
                fs-4
                fw-bolder
                text-gray-900 text-hover-primary
                me-1
                mb-2
                lh-1
              "
              >{{ $t("modals.hotel.newSession") }}</a
            >
          </div>
          <!--end::User-->
        </div>
        <!--end::Title-->
      </div>
      <!--end::Card header-->

      <!--begin::Card body-->
      <div class="card-body" id="kt_chat_messenger_body">
        <!--begin::Messages-->
        <div
          class="scroll-y me-n5 pe-5 h-650px"
          ref="messagesRef"
          data-kt-element="messages"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
          data-kt-scroll-wrappers="#kt_content, #kt_chat_messenger_body"
          data-kt-scroll-offset="-2px"
        >
          <div v-if="messages.length > 0">
            <template v-for="(item, index) in messages" :key="index">
              <div
                class="d-flex justify-content-start mb-10"
                ref="messagesInRef"
                v-if="item.type === 'in'"
              >
                <!--begin::Wrapper-->
                <div class="d-flex flex-column align-items-start">
                  <!--begin::User-->
                  <div class="d-flex align-items-center mb-2">
                    <!--begin::Details-->
                    <div class="ms-3">
                      <a
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          me-1
                        "
                        >{{ item.name }}</a
                      >
                      <span class="text-muted fs-7 mb-1">{{ item.time }}</span>
                    </div>
                    <!--end::Details-->
                  </div>
                  <!--end::User-->

                  <!--begin::Text-->
                  <div
                    class="
                      p-5
                      rounded
                      bg-light-info
                      text-dark
                      fw-bold
                      mw-lg-1000px
                      text-start
                    "
                    data-kt-element="message-text"
                  >
                    {{ item.text }}
                  </div>

                  <!--end::Text-->
                </div>
                <a
                  @click="retryAnswer(item.messageId)"
                  class="
                    d-flex
                    align-items-center
                    text-gray-400 text-hover-primary
                    me-5
                    mb-2
                    ms-3
                    mt-12
                  "
                  ><span class="svg-icon svg-icon-4 me-1">
                    <inline-svg
                      src="/media/icons/duotune/social/soc011.svg" /></span
                  >{{ $t("common.dontLiked") }}</a
                >
              </div>

              <MessageOut
                name="Sen"
                ref="messagesOutRef"
                v-if="item.type === 'out'"
                time="Az önce"
                :image="item.image"
                v-model:text="item.text"
                :emotionAnalysis="item.emotionAnalysis"
              ></MessageOut>

            </template>
            <MessageIn
              v-if="sentMessage == 1 && isSessionError == false"
              name="Asistan"
              time="Az önce"
              image="/media/avatars/ai.jpg"
              text="Yazıyor..."
              :loading="true"
            ></MessageIn>
          </div>
          <div
            v-else
            class="fs-5"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              text-align: center;
            "
          >
            {{ $t("modals.hotel.newChatDesc") }}
          </div>
        </div>
        <!--end::Messages-->
      </div>
      <!--end::Card body-->

      <!--begin::Card footer-->
      <div class="card-footer pt-4" id="kt_chat_messenger_footer">
        <!--begin:Toolbar-->
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-align: center;
            padding-top: 13px;
          "
          v-if="sessionClosed"
        >
          {{ $t("modals.hotel.closedSession") }}
        </div>

        <div v-else class="d-flex flex-stack">
          <!--begin::Input-->
          <textarea
            v-model="newMessageText"
            class="form-control form-control-flush mb-3"
            data-kt-element="input"
            :placeholder="$t('modals.hotel.enterMessage')"
            style="resize: none"
            @keydown.enter="addNewMessage"
          ></textarea>
          <!--end::Input-->

          <!--begin::Send-->
          <button
            @click="addNewMessage"
            class="btn btn-primary"
            type="button"
            data-kt-element="send"
          >
            {{ $t("common.button.send") }}
          </button>
          <!--end::Send-->
        </div>
        <!--end::Toolbar-->
      </div>
      <!--end::Card footer-->
    </div>
    <!--end::Messenger-->
  </div>
  <!--end::Content-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { ScrollComponent } from "../../../assets/ts/components";
import { setCurrentPageTitle } from "../../../helper/Breadcrumb";
import MessageIn from "@/presentation/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/presentation/components/messenger-parts/MessageOut.vue";
import { SessionListModel } from "@/domain/hotel-management/session-list/model/SessionListModel";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { useI18n } from "vue-i18n";
import { SendMessageModel } from "@/domain/hotel-management/send-message/model/SendMessageModel";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { RetryAnswerModel } from "@/domain/hotel-management/retry-answer/model/RetryAnswerModel";
import i18n from "@/presentation/language/i18n";

interface SessionMessages {
  type: string;
  name?: string;
  image?: string;
  time: string;
  text: string;
  messageId?: number;
  emotionAnalysis?: Object;
}

export default defineComponent({
  name: "new-session",
  components: {
    MessageIn,
    MessageOut,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const messagesRef = ref<null | HTMLElement>(null);
    const messagesInRef = ref<null | HTMLElement>(null);
    const messagesOutRef = ref<null | HTMLElement>(null);

    const swalNotification = new SwalNotification();

    const sentMessage = ref<number>(0);
    // 0 işlem bitti veya başlamadı, 1 beklemede,

    const isSessionError = ref<boolean>(false);
    const sessionClosed = ref<boolean>(false);
    const newSession = ref<number>(0);

    const messages = ref<Array<SessionMessages>>([]);

    const sessionList = ref<SessionListModel[]>([]);
    const sessionController = store.state.ControllersModule.sessionController;

    const newMessageText = ref("");

    const thisSession = ref<SendMessageModel>({
      session: {
        productSessionMessages: [],
      },
    });

    function AIMessageFlow(text: string | any, messagesRef: SessionMessages[]) {
      let count = 0;
      let artis = 5;
      let interval = setInterval(() => {
        if (count >= text.length || !messagesRef.length)
          clearInterval(interval);

        const lastMessageIndex = messages.value.length - 1;

        if (lastMessageIndex >= 0 && lastMessageIndex < messagesRef.length) {
          messagesRef[lastMessageIndex].text = text.slice(0, count);
        }
        count += artis;
        ScrollComponent.updateAll();
      }, 100);
    }

    const addNewMessage = () => {
      if (!newMessageText.value) {
        Swal.fire({
          text: "Lütfen boş mesaj göndermeyiniz.",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: t("common.button.ok"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
          heightAuto: false,
        });
        return;
      } else {
        if (messages.value.length == 0) {
          messages.value.push({
            type: "out",
            time: "Az önce",
            text: newMessageText.value,
          });

          const sendButton = document.querySelector(
            '.btn[data-kt-element="send"]'
          );
          if (sendButton) {
            sendButton.setAttribute("disabled", "disabled");
          }

          const sendMessageModel: SendMessageModel = {
            text: newMessageText.value,
            session: {
              productSessionMessages: [],
            },
          };
          newMessageText.value = "";

          sentMessage.value = 1;

          sendMessage(sendMessageModel);
          return;
        } else {
          Swal.fire({
            text: t("modals.hotel.closedSession"),
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: t("common.button.ok"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
            heightAuto: false,
          });
          return;
        }
      }
    };

    const sendMessage = async (sendMessageModel) => {
      if (
        newMessageText.value != "" ||
        newMessageText.value != null ||
        newMessageText.value != undefined
      ) {
        sessionController
          .sendMessage(sendMessageModel)
          .then((response) => {
            if (response.isSuccess) {
              newSession.value += 1;
              sentMessage.value = 0;
              store.dispatch("newChat", newSession.value);

              thisSession.value = response.getValue();
              let length =
                thisSession.value.session.productSessionMessages.length;

                messages.value = thisSession.value.session.productSessionMessages.map((msg) => ({
                  type: msg.owner === "AI" ? "in" : "out",
                  name: msg.owner === "AI" ? "Asistan" : "Sen",
                  image:
                  msg.owner === "USER"
                          ? "/media/avatars/blank.png"
                          : "/media/avatars/ai.jpg",
            time: "Az önce",
            text: msg.text,
            messageId: msg.messageId,
            emotionAnalysis: msg.emotionAnalysis
          }));

              sessionClosed.value = true;
              ScrollComponent.updateAll();
              AIMessageFlow(
                thisSession.value.session.productSessionMessages[length - 1]
                  .text,
                messages.value
              );

              /*
              setTimeout(() => {
                Swal.fire({
                  text: t(SWAL_MESSAGES.SESSION_CLOSED),
                  icon: "success",
                  buttonsStyling: false,
                  showConfirmButton: false,
                  timer: 1500,
                  heightAuto: false,
                });
              }, 200);
              */
            } else {
              isSessionError.value = true;
              swalNotification.error(
                response.error.cause.cause,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            isSessionError.value = true;
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      } else {
        Swal.fire({
          text: t("modals.hotel.emptyMessage"),
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: t("common.button.ok"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
          heightAuto: false,
        }).then(() => {});
      }
      setTimeout(() => {
        if (messagesRef.value) {
          messagesRef.value.scrollTop = messagesRef.value.scrollHeight;
        }
      }, 1);
    };

    const retryAnswer = (messageId) => {
      sentMessage.value = 1;
      const retryAnswerModel: RetryAnswerModel = {
        messageId: Number(messageId),
      };

      sessionController
        .retryAnswer(retryAnswerModel)
        .then((response) => {
          if (response.isSuccess) {
            newSession.value += 1;
            sentMessage.value = 0;
            store.dispatch("newChat", newSession.value);
            
            messages.value.push({
              type: "in",
              name: "Asistan",
              time: "Az önce",
              text: response.getValue().text,
                messageId:
                response.getValue().messageId,
              });

            ScrollComponent.updateAll();
            AIMessageFlow(
              response.getValue()
                  .text,
                messages.value
              );

            sessionClosed.value = true;

          } else {
            isSessionError.value = true;
            swalNotification.error(
              response.error.cause.cause,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          isSessionError.value = true;
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    watch(
      () => store.getters.startNewSession,
      () => {
        if (store.getters.startNewSession) cleanSessionForNewSession();
      }
    );

    const cleanSessionForNewSession = () => {
      messages.value = [];
      sessionClosed.value = false;
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.hotel.title"));

      if (!messagesRef.value) {
        return;
      }

      ScrollComponent.bootstrap();
    });

    return {
      messages,
      messagesRef,
      newMessageText,
      addNewMessage,
      messagesInRef,
      messagesOutRef,
      sessionList,
      sentMessage,
      isSessionError,
      sessionClosed,
      retryAnswer,
    };
  },
});
</script>

((eski olan))
